import Tracker from '@openreplay/tracker';

export const tracker = new Tracker({
  projectKey: process.env.REACT_APP_OPENREPLAY_PROJECT_ID,
  network: {
    failuresOnly: false,
    sessionTokenHeader: true,
    ignoreHeaders: false,
    capturePayload: true,
  },
});
