import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useAuthContext } from 'src/auth/hooks';
// assets
import { ForbiddenIllustration } from 'src/assets/illustrations';
// components
import { MotionContainer, varBounce } from 'src/components/animate';
import { Box, Button, Stack } from '@mui/material';

// ----------------------------------------------------------------------

export default function RoleBasedGuard({ hasContent, roles, children, sx }) {
  // Logic here to get current user role
  const { user, logout } = useAuthContext();

  const adminEmails = [
    'hansraj@flyzygo.com',
    'ritu@flyzygo.com',
    'anjali@flyzygo.com',
    'arjitsingh@flyzygo.com',
    'deepakm@flyzygo.com',
    'omika@flyzygo.com',
    'lakshay@flyzygo.com',
    'piyush@flyzygo.com',
    'amartya@flyzygo.com',
    'hetal@flyzygo.com',
    'bonny@flyzygo.com',
  ];

  if (!adminEmails.includes(user?.email)) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center', ...sx }}>
        <Box height={80} />

        <Stack alignItems="flex-end">
          <Button color="error" onClick={logout}>
            Logout
          </Button>
        </Stack>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            You do not have permission to access this page
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration
            sx={{
              height: 260,
              my: { xs: 5, sm: 10 },
            }}
          />
        </m.div>
      </Container>
    ) : null;
  }

  return <> {children} </>;
}

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  hasContent: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
  sx: PropTypes.object,
};
